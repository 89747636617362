import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getUsers(params) {
  return request({
    url: `${ENDPOINT.USER}/list`,
    method: 'get',
    params
  })
}

export function createUser(data) {
  return request({
    url: ENDPOINT.USER,
    method: 'post',
    data
  })
}

export function deleteUser(id) {
  return request({
    url: ENDPOINT.USER,
    method: 'delete',
    params: {
      id: id
    }
  })
}
export function updateUser(data) {
  return request({
    url: ENDPOINT.USER,
    method: 'patch',
    data
  })
}
