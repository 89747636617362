<template>
  <div class="home">
    <div class="home__header">
      <span>Thống kê</span>
      <el-form :model="form" :inline="true">
        <el-form-item prop="dates">
          <el-date-picker
            v-model="form.dates"
            type="daterange"
            range-separator="Đến"
            start-placeholder="Ngày bắt đầu"
            end-placeholder="Ngày kết thúc"
            value-format="yyyy-MM-ddT00:00:00"
            format="dd-MM-yyyy"
            :clearable="false"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="handleFilterData">Tìm kiếm</el-button>
        </el-form-item>
      </el-form>
    </div>
    <statistical :dates="form.dates" />
    <receipts :dates="form.dates" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Statistical from './components/Statistical'
import Receipts from './components/Receipts'

export default {
  name: 'Home',
  components: {
    Statistical,
    Receipts
  },
  data() {
    return {
      form: {
        dates: [dayjs().subtract(7, 'day').format('YYYY-MM-DDT00:00:00'), dayjs().format('YYYY-MM-DDT00:00:00')]
      }
    }
  },
  methods: {
    handleFilterData() {}
  }
}
</script>

<style lang="scss" scoped>
.home {
  margin-bottom: 50px;
  border: 1px solid #f5f5f5;
  &__header {
    padding: 12px;
    background-color: #DCDFE6;
    font-weight: 700;
    font-size: 18px;
    color: #409eff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss">
  .home {
    .el-range-separator {
      width: 6%;
    }
  }
</style>

