<template>
  <div class="receipt-page shared-index">
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane name="receipt">
            <div slot="label">Danh sách đơn hàng ({{ total }})</div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="reloadData"
        >Làm mới</el-button>
      </div>
      <div class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Nhập mã đơn hàng để tìm kiếm"
          size="small"
          @input="handleSearch"
        ></el-input>
      </div>
    </el-row>
    <div v-loading="callingAPI" class="table">
      <list-receipts
        :data="listReceipts"
        @reload="loadData"
        @update="handleOpenForm"
        @show="handleOpenDetail"
      ></list-receipts>
    </div>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <receipt-form-popup
      v-if="formVisible"
      :visible="formVisible"
      :data="selectedReceipt"
      @close="formVisible = false"
      @reload="loadData"
    ></receipt-form-popup>
    <receipt-detail-popup
      v-if="detailVisible"
      :visible="detailVisible"
      :data="selectedReceipt"
      @close="detailVisible = false"
    ></receipt-detail-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getReceipts } from '@/services/receipt'
import ListReceipts from './receipts/List'
import ReceiptFormPopup from './popups/Form.vue'
import ReceiptDetailPopup from './popups/Detail.vue'

export default {
  name: 'Receipts',
  components: {
    ListReceipts,
    ReceiptFormPopup,
    ReceiptDetailPopup
  },
  props: {
    dates: Array
  },
  data() {
    return {
      callingAPI: false,
      searchText: '',
      activeTab: 'receipt',
      limit: 10,
      total: 0,
      page: 1,
      listReceipts: [],
      formVisible: false,
      detailVisible: false,
      selectedReceipt: null
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  watch: {
    language: 'reloadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.callingAPI = true
      this.formVisible = false
      const params = {
        page: this.page,
        per_page: this.limit,
        search_text: this.searchText,
        locale: this.language
      }
      await getReceipts(params).then((response) => {
        this.listReceipts = response.result
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },

    reloadData() {
      this.searchText = ''
      this.page = 1
      this.loadData()
    },

    handleChangeTab() {
      this.page = 1
      this.loadData()
    },

    handleChangePage(page) {
      this.page = page
      this.loadData()
    },

    handleChangeSize(size) {
      this.page = 1
      this.limit = size
      this.loadData()
    },

    handleSearch() {
      this.page = 1
      this.loadData()
    },

    handleOpenForm(receipt) {
      this.selectedReceipt = receipt
      this.formVisible = true
    },

    handleOpenDetail(receipt) {
      this.selectedReceipt = receipt
      this.detailVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
