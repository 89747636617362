<template>
  <el-table :data="data">
    <el-table-column label="STT" type="stt" width="50"></el-table-column>
    <el-table-column label="Mã đơn hàng" width="200">
      <template slot-scope="scope">
        <a href="javascript:;" @click="handleUpdateReceipt(scope.row)">{{ scope.row.code }}</a>
      </template>
    </el-table-column>
    <el-table-column label="Tên khách hàng" width="250">
      <template slot-scope="scope">
        <p>{{ scope.row.customer_name }}</p>
      </template>
    </el-table-column>
    <el-table-column label="Email" prop="email" width=""></el-table-column>
    <el-table-column label="Tổng tiền" prop="total" width="200" align="center">
      <template slot-scope="scope">
        <b>{{ scope.row.total | numberWithDelimiter }}</b>
      </template>
    </el-table-column>
    <el-table-column label="Ngày mua" prop="created_at" width="200" align="center"></el-table-column>
    <el-table-column label="Trạng thái" align="center" width="150">
      <template slot-scope="scope">
        <el-tag v-if="scope.row.status === 'open'" type="info" size="small">Chưa thanh toán</el-tag>
        <el-tag v-else type="success" size="small">Đã thanh toán</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="Thao tác" width="150" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Chi tiết đơn hàng" placement="top">
          <el-button icon="el-icon-tickets" type="primary" circle size="small" @click="$emit('show', scope.row)"></el-button>
        </el-tooltip>
        <el-tooltip content="Chỉnh sửa" placement="top">
          <el-button icon="el-icon-edit" type="primary" circle size="small" @click="handleUpdateReceipt(scope.row)"></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa user" placement="top">
          <el-button icon="el-icon-delete" circle type="danger" size="small" @click="handleDeleteReceipt(scope.row.id)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { deleteUser } from '@/services/user'
export default {
  name: 'ListReceipts',
  props: {
    data: Array
  },
  data() {
    return {}
  },
  methods: {
    handleDeleteReceipt(id) {
      this.$confirm('Bạn có chắc chắn muốn xóa đơn hàng này?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(async () => {
        await deleteUser(id).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa đơn hàng thành công'
          })
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Xóa đơn hàng thất bại'
          })
        })
      }).catch(() => {})
    },

    handleUpdateReceipt(row) {
      this.$emit('update', row)
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: #1174d4;
}
</style>
