<template>
  <div class="statistical">
    <el-row :gutter="24" class="statistical__content">
      <el-col :lg="8">
        <el-card v-loading="callingAPI" shadow="always">
          <el-row type="flex" align="middle" justify="center">
            <img src="@/assets/images/shopping-bag.png" alt="" />
            <div class="statistical__content--detail">
              <p class="title">Tổng đơn hàng</p>
              <b class="info">{{ totalQuantity | numberWithDelimiter }}</b>
            </div>
          </el-row>
        </el-card>
      </el-col>
      <el-col :lg="8">
        <el-card v-loading="callingAPI" shadow="always">
          <el-row type="flex" align="middle" justify="center">
            <img src="@/assets/images/money-bag.png" alt="" />
            <div class="statistical__content--detail">
              <p class="title">Tổng tiền đã thanh toán</p>
              <b class="info">{{ paidTotal | numberWithDelimiter }} VNĐ</b>
            </div>
          </el-row>
        </el-card>
      </el-col>
      <el-col :lg="8">
        <el-card v-loading="callingAPI" shadow="always">
          <el-row type="flex" align="middle" justify="center">
            <img src="@/assets/images/money.png" alt="" />
            <div class="statistical__content--detail">
              <p class="title">Tổng tiền chưa thanh toán</p>
              <b class="info">{{ openedTtotal | numberWithDelimiter }} VNĐ</b>
            </div>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getReceiptStatistic } from '@/services/receipt'
export default {
  name: 'Statistical',
  props: {
    dates: Array
  },
  data() {
    return {
      callingAPI: false,
      totalQuantity: 0,
      paidTotal: 0,
      openedTtotal: 0,
      form: {
        dates: this.dates
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.callingAPI = true
      getReceiptStatistic({
        from_time: this.form.dates[0] || '',
        to_time: this.form.dates[1] || ''
      }).then((response) => {
        this.totalQuantity = response.total_quantity
        this.paidTotal = response.paid_total
        this.openedTtotal = response.opened_total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.statistical {
  margin-bottom: 50px;
  border: 1px solid #dcdfe6;
  &__content {
    padding: 30px 12px;
    .el-card {
      height: 120px;
    }
    &--detail {
      text-align: center;
      margin-left: 20px;
      .title {
        font-size: 16px;
        font-weight: 700;
        color: #676767;
      }
      .info {
        font-size: 24px;
      }
    }
  }
}
</style>
