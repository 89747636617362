
<template>
  <el-dialog
    width="720px"
    class="receipt-detail-popup"
    title="Thông tin chi tiết đơn hàng"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-table :data="detailData">
      <el-table-column label="STT" prop="stt" width="50" />
      <el-table-column label="Tên Không gian văn hoá/ Tour" width="150">
        <template slot-scope="scope">
          <p>{{ scope.row.object.name }}</p>
        </template>
      </el-table-column>
      <el-table-column label="Packages" align="center">
        <template slot-scope="scope">
          <div v-for="p in scope.row.packages" :key="p.id">
            <p><b>{{ p.name }}</b></p>
            <p>{{ p.total | numberWithDelimiter }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Tổng tiền (VNĐ)" width="130" align="center">
        <template slot-scope="scope">
          <b>{{ scope.row.total | numberWithDelimiter }}</b>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">Đóng</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTranslation } from '@/utils/filters'
export default {
  name: 'ReceiptDetail',
  props: {
    visible: Boolean,
    data: Object
  },
  computed: {
    ...mapGetters(['language']),
    detailData() {
      const details = []
      const invoices = this.data.invoices.map((i) => ({
        object: i.places[0] || i.tours[0],
        package: { ...i.package, price: i.subtotal }
      }))
      const objectIds = [... new Set(invoices.map((i) => i.object.id))]
      objectIds.map((id) => {
        const obInvoices = invoices.filter((i) => i.object.id === id)
        const obPackages = obInvoices.map((i) => i.package)
        const packageIds = [... new Set(obPackages.map((p) => p.id))]
        const packages = packageIds.map((id) => {
          const packs = obPackages.filter((p) => p.id === id)
          return {
            name: packs[0].summary,
            type: packs[0].package_type,
            total: packs.map((p) => p.price).reduce((a, b) => a + b)
          }
        })
        details.push({
          object: { ...obInvoices[0].object, name: getTranslation(obInvoices[0].object).name },
          packages: packages,
          total: packages.map((p) => p.total).reduce((a, b) => a + b)
        })
      })
      return details.map((d, index) => ({ stt: index + 1, ...d }))
    }
  }
}
</script>

<style lang="scss" scoped>
.receipt-detail-popup {
  p {
    word-break: break-word;
  }
}
</style>
